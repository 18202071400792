import './Bubble.scss'

import { useState, useRef, useEffect, useLayoutEffect } from 'react'

export function Bubble({ children }) {
  const ref = useRef()
  const [x, setX] = useState(0)

  const maybeSetX = () => {
    const node: HTMLDivElement = ref.current
    if (!node) return
    const sibling: HTMLDivElement = node.previousElementSibling
    if (!sibling) return
    const selectedBtn = sibling.querySelector('.MuiButton-contained')
    if (!selectedBtn) return
    const x = Math.round(selectedBtn.offsetLeft - 42 + selectedBtn.clientWidth / 2)
    setX(x)
  }

  useLayoutEffect(() => {
    maybeSetX()
  }, [children])

  useEffect(() => {
    setTimeout(maybeSetX, 300)
  }, [])

  return (
    <div className="Bubble" ref={ref}>
      {children}
      <svg viewBox="0 0 14 8" width="14" height="8" style={{ left: `${x}px` }}>
        <path d={`M 0 8 l 7 -8 l 7 8`} stroke="#dadae5" fill="#fafaff" />
        <path d={`M 0 8 l 14 0`} stroke="#fafaff" fill="none" />
      </svg>
    </div>
  )
}

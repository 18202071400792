import './ReportPage.scss'

import { useEffect, useState } from 'react'
import { NavLink, Route, useRouteMatch } from 'react-router-dom'
import _ from 'lodash'
// eslint-disable-next-line no-unused-vars
import { Avatar, Chip, Card, CardContent } from '@mui/material'
import {
  OpenInNew,
  Assessment as AssessmentIcon,
  Business as BusinessIcon,
  Category as MuiCategoryIcon
} from '@mui/icons-material'
import * as api from '../api'
import { LOADING } from '../constants'
import { Error404 } from './errors'
import { ContainerSpinner } from './spinners'
import { Fields } from './Fields'
import { TitleBar } from './TitleBar'
import { CategoryIcon } from './CategoryIcon'
import { Cards } from './Cards'

export function ReportPage() {
  const match = useRouteMatch()
  return (
    <div className="ReportPage">
      <Route path={`${match.path}/:id`}>
        <ReportDetail />
      </Route>
    </div>
  )
}

export function ReportDetail() {
  const [report, setReport] = useState(LOADING)
  const match = useRouteMatch()
  const id = match.params.id
  useEffect(() => {
    api.reports
      .get(id + '?include=report.org_id&include=report.related_reports_by_indicator')
      .then(report => setReport(report))
      .catch(err => {
        console.error(err)
        setReport(null)
      })
  }, [id])

  if (report === LOADING) return <ContainerSpinner />
  if (!report) return <Error404 />

  // prettier-ignore
  const fields = _.compact([
    report.org_id ? ['Publisher', (<div className="source-links"><NavLink to={`/orgs/${report.org_id}`}>{report.source_name}</NavLink>{report.source_link ? (<a href={report.source_link} target="_blank" rel="noreferrer">(Link to source <OpenInNew sx={{ fontSize: 12 }} />)</a>) : (<span className="disabled-text">(Link missing)</span>)}</div>)] : null,
    report.date ? ['Years Available', report.date && report.date2 && report.date !== report.date2 ? `${report.date} - ${report.date2}` : report.date] : null,
    ['Level of Relevance', report.is_local ? 'Local' : 'Non-local'],
    // report.indicators.length ? ['Indicators Cited', <div className="links">{report.indicators.map(i => <NavLink key={i.id} to={`/indicators/${i.id}`}>{i.name}</NavLink>)}</div>] : null,
    // report.indicators.length ? ['Related Categories', <div className="links">{_.uniqBy(report.indicators.map(i => i.category), 'id').map(c => <NavLink key={c.id} to={`/?type=ALL&subcategories=${subcategories.filter(sub => sub.category === c.id).map(sub => sub.id).join(',')}`}>{c.name} <CategoryIcon name={c.name}/></NavLink>)}</div>] : null,
    // report.funders.length ? ['Funders', <div className="links">{report.funders.map(f => <NavLink key={f.id} to={`/orgs/${f.id}`}>{f.name}</NavLink>)}</div>] : null,
    // report.collectors.length ? ['Collectors', <div className="links">{report.collectors.map(c => <NavLink key={c.id} to={`/orgs/${c.id}`}>{c.name}</NavLink>)}</div>] : null,
    // report.evaluators.length ? ['Evaluators', <div className="links">{report.evaluators.map(e => <NavLink key={e.id} to={`/orgs/${e.id}`}>{e.name}</NavLink>)}</div>] : null,
    report.related_reports_by_indicator.length ? ['Similar reports', <div className="links">{report.related_reports_by_indicator.map(r => <NavLink key={r.id} to={`/reports/${r.id}`}>{r.name}</NavLink>)}</div>] : null,
  ])

  let cards = []
  if (report.indicators.length) {
    cards.push(
      <Card key="indicators">
        <CardContent>
          <div className="header">
            <AssessmentIcon fontSize="medium" sx={{ color: 'rgb(241, 101, 37)' }} />
            <div className="card-title">Indicators Cited</div>
          </div>
          <div className="links">
            {report.indicators.map(i => (
              <NavLink key={i.id} to={`/indicators/${i.id}`}>
                {i.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }
  if (report.indicators.length) {
    cards.push(
      <Card key="categories">
        <CardContent>
          <div className="header">
            <MuiCategoryIcon fontSize="medium" sx={{ color: '#4279bf' }} />
            <div className="card-title">Related Categories</div>
          </div>
          <div className="links">
            {_.uniqBy(
              report.indicators.map(i => i.category),
              'id'
            ).map(c => (
              <NavLink key={c.id} to={`/categories/${c.id}`}>
                <span>{c.name}</span>
                <CategoryIcon name={c.name} />
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }
  if (report.funders.length) {
    cards.push(
      <Card key="funders">
        <CardContent>
          <div className="header">
            <BusinessIcon fontSize="medium" sx={{ color: 'rgb(0, 153, 80)' }} />
            <div className="card-title">Funders</div>
          </div>
          <div className="links">
            {report.funders.map(o => (
              <NavLink key={o.id} to={`/orgs/${o.id}`}>
                {o.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }

  return (
    <div className="ReportDetail container pb100">
      <TitleBar>
        {report.type || 'Report'}: {report.name}
      </TitleBar>
      <Fields fields={fields} />
      <Cards>{cards}</Cards>
    </div>
  )
}

export function ReportListItem({ data }) {
  return (
    <div className="ReportListItem">
      <div className="header">{data.name}</div>
    </div>
  )
}

// eslint-disable-next-line no-unused-vars
const alts = {
  I: 'Indicator',
  F: 'Funder',
  C: 'Collector',
  E: 'Evaluator'
}

import { calculateMosaic } from '../util'

import './Cards.scss'

export function Cards({ mosaic = false, children }) {
  const onRef = (node: HTMLDivElement) => {
    if (node && mosaic) {
      calculateMosaic(node)
    }
  }

  return (
    <div className={`Cards ${mosaic ? 'mosaic' : ''}`} ref={onRef}>
      {children}
    </div>
  )
}

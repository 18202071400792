import './SubcategoryPage.scss'

import { useEffect, useState } from 'react'
import { NavLink, Route, useRouteMatch } from 'react-router-dom'
import { Card, CardContent } from '@mui/material'
import {
  Assessment as AssessmentIcon,
  Description as DescriptionIcon,
  Dashboard as DashboardIcon,
  Business as BusinessIcon
} from '@mui/icons-material'
import * as api from '../api'
import { LOADING } from '../constants'
import { Error404 } from './errors'
import { ContainerSpinner } from './spinners'
import { TitleBar } from './TitleBar'
import { Cards } from './Cards'

export function SubcategoryPage() {
  const match = useRouteMatch()
  return (
    <div className="SubcategoryPage">
      <Route path={`${match.path}/:id`}>
        <SubcategoryDetail />
      </Route>
    </div>
  )
}

export function SubcategoryDetail() {
  const [subcategory, setSubcategory] = useState(LOADING)
  const match = useRouteMatch()
  const id = match.params.id
  useEffect(() => {
    api.subcategories
      .get(id)
      .then(category => setSubcategory(category))
      .catch(err => {
        console.error(err)
        setSubcategory(null)
      })
  }, [id])

  if (subcategory === LOADING) return <ContainerSpinner />
  if (!subcategory) return <Error404 />

  return (
    <div className="SubcategoryDetail container pb100">
      <TitleBar>Subcategory: {subcategory.name}</TitleBar>
      <SubcategoryCards subcategory={subcategory} />
    </div>
  )
}

export function SubcategoryCards({ subcategory }) {
  const reports = subcategory.reports.filter(r => r.type === 'Report')
  const dashboards = subcategory.reports.filter(r => r.type === 'Dashboard')
  let cards = []

  if (subcategory.indicators.length) {
    cards.push(
      <Card key="indicators">
        <CardContent>
          <div className="header">
            <AssessmentIcon fontSize="medium" sx={{ color: 'rgb(241, 101, 37)' }} />
            <div className="card-title">Indicators</div>
          </div>
          <div className="links">
            {subcategory.indicators.map(i => (
              <NavLink key={i.id} to={`/indicators/${i.id}`}>
                {i.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }

  if (reports.length) {
    cards.push(
      <Card key="reports">
        <CardContent>
          <div className="header">
            <DescriptionIcon fontSize="medium" sx={{ color: 'rgb(234, 120, 65)' }} />
            <div className="card-title">Reports</div>
          </div>
          <div className="links">
            {reports.map(r => (
              <NavLink key={r.id} to={`/reports/${r.id}`}>
                {r.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }

  if (dashboards.length) {
    cards.push(
      <Card key="dashboards">
        <CardContent>
          <div className="header">
            <DashboardIcon fontSize="medium" sx={{ color: 'rgb(241, 101, 37)' }} />
            <div className="card-title">Dashboards</div>
          </div>
          <div className="links">
            {dashboards.map(r => (
              <NavLink key={r.id} to={`/reports/${r.id}`}>
                {r.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }

  if (subcategory.orgs.length) {
    cards.push(
      <Card key="orgs">
        <CardContent>
          <div className="header">
            <BusinessIcon fontSize="medium" sx={{ color: 'rgb(0, 153, 80)' }} />
            <div className="card-title">Organizations</div>
          </div>
          <div className="links">
            {subcategory.orgs.map(o => (
              <NavLink key={o.id} to={`/orgs/${o.id}`}>
                {o.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }

  if (!cards.length) {
    return null
  }

  return (
    <div className="SubcategoryCards">
      <Cards>{cards}</Cards>
    </div>
  )
}

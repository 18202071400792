import './spinners.scss'

import { CircularProgress } from '@mui/material'

export function Spinner() {
  return (
    <div className="Spinner">
      <CircularProgress />
    </div>
  )
}

export function ContainerSpinner() {
  return (
    <div className="ContainerSpinner container">
      <CircularProgress />
    </div>
  )
}

import './OrgPage.scss'

import { useEffect, useState } from 'react'
import { NavLink, Route, useRouteMatch } from 'react-router-dom'
import { Card, CardContent } from '@mui/material'
import {
  Assessment as AssessmentIcon,
  Description as DescriptionIcon,
  Category as MuiCategoryIcon,
  Dashboard as DashboardIcon
} from '@mui/icons-material'
import * as api from '../api'
import { LOADING } from '../constants'
import { Error404 } from './errors'
import { ContainerSpinner } from './spinners'
import { Fields } from './Fields'
import { TitleBar } from './TitleBar'
import { CategoryIcon } from './CategoryIcon'
import { Cards } from './Cards'

export function OrgPage() {
  const match = useRouteMatch()
  return (
    <div className="OrgPage">
      <Route path={`${match.path}/:id`}>
        <OrgDetail />
      </Route>
    </div>
  )
}

export function OrgDetail() {
  const [org, setOrg] = useState(LOADING)
  const match = useRouteMatch()
  const id = match.params.id
  useEffect(() => {
    api.orgs
      .get(
        id + '?include=org.indicators&include=org.evaluated_reports&include=org.funded_reports&include=org.categories'
      )
      .then(org => setOrg(org))
      .catch(err => {
        console.error(err)
        setOrg(null)
      })
  }, [id])

  if (org === LOADING) return <ContainerSpinner />
  if (!org) return <Error404 />

  const fields = []
  const cards = []

  const er = org.evaluated_reports.filter(r => r.type === 'Report')
  const ed = org.evaluated_reports.filter(r => r.type === 'Dashboard')
  const fr = org.funded_reports.filter(r => r.type === 'Report')
  const fd = org.funded_reports.filter(r => r.type === 'Dashboard')
  if (er.length) {
    // fields.push(['Reports Published', <div className="links">{er.map(r => <NavLink key={r.id} to={`/reports/${r.id}`}>{r.name}</NavLink>)}</div>])
    cards.push(
      <Card key="er">
        <CardContent>
          <div className="header">
            <DescriptionIcon fontSize="medium" sx={{ color: 'rgb(241, 101, 37)' }} />
            <div className="card-title">Reports Published</div>
          </div>
          <div className="links">
            {er.map(r => (
              <NavLink key={r.id} to={`/reports/${r.id}`}>
                {r.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }
  if (ed.length) {
    // fields.push(['Dashboards Published', <div className="links">{ed.map(r => <NavLink key={r.id} to={`/reports/${r.id}`}>{r.name}</NavLink>)}</div>])
    cards.push(
      <Card key="ed">
        <CardContent>
          <div className="header">
            <DashboardIcon fontSize="medium" sx={{ color: 'rgb(151, 39, 179)' }} />
            <div className="card-title">Dashboards Published</div>
          </div>
          <div className="links">
            {ed.map(r => (
              <NavLink key={r.id} to={`/reports/${r.id}`}>
                {r.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }
  if (fr.length) {
    // fields.push(['Reports Funded', <div className="links">{fr.map(r => <NavLink key={r.id} to={`/reports/${r.id}`}>{r.name}</NavLink>)}</div>])
    cards.push(
      <Card key="fr">
        <CardContent>
          <div className="header">
            <DescriptionIcon fontSize="medium" sx={{ color: 'rgb(241, 101, 37)' }} />
            <div className="card-title">Reports Funded</div>
          </div>
          <div className="links">
            {fr.map(r => (
              <NavLink key={r.id} to={`/reports/${r.id}`}>
                {r.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }
  if (fd.length) {
    // fields.push(['Dashboards Funded', <div className="links">{fd.map(r => <NavLink key={r.id} to={`/reports/${r.id}`}>{r.name}</NavLink>)}</div>])
    cards.push(
      <Card key="fd">
        <CardContent>
          <div className="header">
            <DashboardIcon fontSize="medium" sx={{ color: 'rgb(151, 39, 179)' }} />
            <div className="card-title">Dashboards Funded</div>
          </div>
          <div className="links">
            {fd.map(r => (
              <NavLink key={r.id} to={`/reports/${r.id}`}>
                {r.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }
  if (org.indicators?.length) {
    // fields.push(['Indicators Collected', <div className="links">{org.indicators.map(i => <NavLink key={i.id} to={`/indicators/${i.id}`}>{i.name}</NavLink>)}</div>])
    cards.push(
      <Card key="indicators">
        <CardContent>
          <div className="header">
            <AssessmentIcon fontSize="medium" sx={{ color: 'rgb(241, 101, 37)' }} />
            <div className="card-title">Indicators Collected</div>
          </div>
          <div className="links">
            {org.indicators.map(i => (
              <NavLink key={i.id} to={`/indicators/${i.id}`}>
                {i.name}
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }
  if (org.categories?.length) {
    // fields.push(['Related Categories', <div className="links">{org.categories.map(c => <NavLink key={c.id} to={`/?type=ALL&subcategories=${c.subcategories.map(sub => sub.id).join(',')}`} onClick={reload}>{c.name} <CategoryIcon name={c.name}/></NavLink>)}</div>])
    cards.push(
      <Card key="categories">
        <CardContent>
          <div className="header">
            <MuiCategoryIcon fontSize="medium" sx={{ color: '#4279bf' }} />
            <div className="card-title">Related Categories</div>
          </div>
          <div className="links" style={{ maxHeight: 250 }}>
            {org.categories.map(c => (
              <NavLink key={c.id} to={`/categories/${c.id}`}>
                <span>{c.name}</span>
                <CategoryIcon name={c.name} />
              </NavLink>
            ))}
          </div>
          <div className="fade-bar" />
        </CardContent>
      </Card>
    )
  }

  return (
    <div className="OrgDetail container pb100">
      <TitleBar>Organization: {org.name}</TitleBar>

      <div className="row row1">
        <Fields fields={fields} />
      </div>
      <Cards mosaic>{cards}</Cards>
    </div>
  )
}

export function OrgListItem({ data }) {
  return (
    <div className="OrgListItem">
      <div className="header">{data.name}</div>
    </div>
  )
}

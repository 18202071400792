import './Filters.scss'

import { TextField, Checkbox, FormControlLabel } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { SearchType } from '../constants'
import { CategoryIcon } from './CategoryIcon'

export function Filters({ searchType, values, options, onChange }) {
  const funders = options.funders || []
  const funderIds = values.funder_ids || []
  const selectedFunders = funders.filter(f => funderIds.includes(f.id))

  const evaluators = options.evaluators || []
  const evaluatorIds = values.evaluator_ids || []
  const selectedEvaluators = evaluators.filter(e => evaluatorIds.includes(e.id))

  // const collectors = options.collectors || []
  // const collectorIds = values.collector_ids || []
  // const selectedCollectors = collectors.filter(c => collectorIds.includes(c.id))

  const categories = options.categories?.[searchType] || []
  const subcategories = options.subcategories?.[searchType] || []
  const selectedSubcategories = values.subcategories || []

  return (
    <div className="Filters">
      <div className="inner">
        {searchType === SearchType.REPORT ? (
          <div className="autocompletes">
            <Autocomplete
              id="autocomplete-evaluator"
              multiple
              options={evaluators}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={option => evaluatorIds.includes(option.id)}
              value={selectedEvaluators}
              fullWidth
              renderInput={params => (
                <TextField
                  {...params}
                  label="Publisher"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  autoCapitalize="off"
                />
              )}
              onChange={(e, arr) => {
                onChange(
                  'evaluator_ids',
                  arr.map(o => o.id)
                )
              }}
            />
            <Autocomplete
              id="autocomplete-funder"
              multiple
              options={funders}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={option => funderIds.includes(option.id)}
              value={selectedFunders}
              fullWidth
              renderInput={params => (
                <TextField
                  {...params}
                  label="Funder"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  autoCapitalize="off"
                />
              )}
              onChange={(e, arr) => {
                onChange(
                  'funder_ids',
                  arr.map(o => o.id)
                )
              }}
            />
          </div>
        ) : null}
        <div className="categories">
          <div className="filter-name">CATEGORY</div>
          <div className="categories-scroll">
            {categories.map((cat, i) => (
              <div key={i} className="category">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={subcategories
                        .filter(sub => sub.category_id === cat.id)
                        .every(sub => selectedSubcategories.includes(sub.id))}
                      onChange={e => {
                        if (e.target.checked) {
                          onChange({
                            ...values,
                            subcategories: subcategories
                              .filter(
                                sub =>
                                  sub.category_id === cat.id || !!selectedSubcategories.find(subId => subId === sub.id)
                              )
                              .map(sub => sub.id)
                          })
                        } else {
                          onChange({
                            ...values,
                            subcategories: selectedSubcategories.filter(
                              id => subcategories.find(sub => sub.id === id).category_id !== cat.id
                            )
                          })
                        }
                      }}
                      color="secondary"
                      size="small"
                    />
                  }
                  label={
                    <>
                      <span className="category-name">{cat.name}</span>
                      <CategoryIcon name={cat.name} />
                    </>
                  }
                />

                <div className="subcategories">
                  {(options.subcategories?.[searchType] || [])
                    .filter(s => s.category_id === cat.id)
                    .map((sub, j) => (
                      <div key={j} className="subcategory">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={(values.subcategories || []).includes(sub.id)}
                              onChange={e => {
                                if (e.target.checked) {
                                  onChange({ ...values, subcategories: [...(values.subcategories || []), sub.id] })
                                } else {
                                  onChange({
                                    ...values,
                                    subcategories: (values.subcategories || []).filter(id => id !== sub.id)
                                  })
                                }
                              }}
                              color="secondary"
                              size="small"
                            />
                          }
                          label={sub.name}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

import { BrowserRouter, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material'
import { ReportPage } from './ReportPage'
import { OrgPage } from './OrgPage'
import { HomePage } from './HomePage'
import { IndicatorPage } from './IndicatorPage'
import { CategoryPage } from './CategoryPage'
import { SubcategoryPage } from './SubcategoryPage'

export function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  )
}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(241,101,37)'
    },
    secondary: {
      main: 'rgb(21, 55, 80)'
    },
    background: {
      default: '#fafafa'
    }
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 3
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0
        }
      }
    }
  }
})

export function Providers(props) {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </BrowserRouter>
  )
}

// prettier-ignore
const routes = [
  ['/reports', ReportPage],
  ['/orgs', OrgPage],
  ['/indicators', IndicatorPage],
  ['/categories', CategoryPage],
  ['/subcategories', SubcategoryPage],
]

export function Routes(props) {
  return (
    <>
      {routes.map(([path, Component]) => (
        <Route key={path} path={path}>
          <Component />
        </Route>
      ))}
      <Route exact path="/">
        <HomePage />
      </Route>
    </>
  )
}

import axios from 'axios'

class Resource {
  constructor(name = '') {
    this.name = name
  }

  async search(searchText) {
    const res = await axios.get(`/${this.name}?q=${searchText}`)
    return res.data
  }

  async list() {
    const res = await axios.get(`/${this.name}`)
    return res.data
  }

  async get(id) {
    const res = await axios.get(`/${this.name}/${id}`)
    return res.data
  }
}

export const reports = new Resource('reports')
export const orgs = new Resource('orgs')
export const funders = new Resource('funders')
export const evaluators = new Resource('evaluators')
export const indicators = new Resource('indicators')
export const collectors = new Resource('collectors')
export const filters = new Resource('filters')
export const categories = new Resource('categories')
export const subcategories = new Resource('subcategories')

export async function search(params: URLSearchParams) {
  const url = `/search?${params.toString()}`
  const res = await axios.get(url)
  return res.data
}

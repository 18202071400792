import { useState } from 'react'
import { Menu as MuiMenu, MenuItem, IconButton, ListItemIcon } from '@mui/material'
import { Menu as MenuIcon, ContentCopy as CopyIcon } from '@mui/icons-material'
import { Toast } from './Toast'

import './Menu.scss'

let timer

export function Menu() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [toast, _setToast] = useState('')
  const setToast = toast => {
    _setToast(toast)
    clearTimeout(timer)
    timer = setTimeout(() => setToast(''), 3000)
  }

  const open = Boolean(anchorEl)

  const onClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton className="Menu" onClick={onClick}>
        <MenuIcon />
      </IconButton>
      <MuiMenu id="Menu" anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
        <CopyLinkMenuItem setToast={setToast} />
      </MuiMenu>
      {toast ? <Toast>{toast}</Toast> : null}
    </>
  )
}

function CopyLinkMenuItem({ setToast }) {
  const onClick = async () => {
    const link = 'https://www.hawaiidata.org/data-finder#anchor1' + window.location.search
    await navigator.clipboard.writeText(link)
    setToast('Copied')
  }

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <CopyIcon />
      </ListItemIcon>
      Save Current Search Link
    </MenuItem>
  )
}

import './SearchResults.scss'

import { Link, NavLink } from 'react-router-dom'
import _ from 'lodash'
import {
  Assessment as AssessmentIcon,
  Description as DescriptionIcon,
  Business as BusinessIcon
} from '@mui/icons-material'
import { SearchType } from '../constants'
import { ReportListItem } from './ReportPage'
import { IndicatorListItem } from './IndicatorPage'
import { OrgListItem } from './OrgPage'
import { Fields } from './Fields'
import { useEffect } from 'react'
import { useState } from 'react'

export function SearchResults({ searchType, searchResults }) {
  const [showCategories, setShowCategories] = useState(false)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const show = ![null, ''].includes(params.get('subcategories'))
    setShowCategories(show)
  }, [])

  const components = {
    [SearchType.REPORT]: [ReportListItem, 'reports'],
    [SearchType.INDICATOR]: [IndicatorListItem, 'indicators'],
    [SearchType.ORG]: [OrgListItem, 'orgs'],
    [SearchType.ALL]: [CombinedListItem, null]
  }

  const [Component, baseUrl] = components[searchType]

  let ui
  if (searchType === SearchType.ALL) {
    const groups = _.groupBy(searchResults, 'cname')
    const list = []
    const keys = Object.keys(groups).sort()
    for (const cname of keys) {
      const results = groups[cname]
      list.push(
        <div key={cname} className="group">
          {Object.keys(groups).length > 1 ? <div className="cname">{cname}</div> : null}
          <div className="items">
            {results
              .filter(data => data.indicators.length + data.reports.length + data.orgs.length > 0)
              .map((data, i) => (
                <div key={i} className="SearchResult">
                  <Component data={data} />
                </div>
              ))}
          </div>
        </div>
      )
    }

    ui = <div className="SearchResults all">{list}</div>
  } else if (showCategories) {
    let categories = []
    let subcategories = []
    for (const r of searchResults) {
      for (const c of r.categories) {
        if (!categories.find(c2 => c2.id === c.id)) {
          categories.push(c)
        }
        for (const s of c.subcategories) {
          s.category_id = c.id
          if (!subcategories.find(s2 => s2.id === s.id)) {
            subcategories.push(s)
          }
        }
      }
    }
    categories = _.sortBy(categories, 'name')
    subcategories = _.sortBy(subcategories, 'name')
    const list1 = []
    for (const c of categories) {
      let list2 = []
      for (const s of subcategories.filter(s => s.category_id === c.id)) {
        const results = searchResults.filter(r => {
          const cids = r.categories.map(c => c.id)
          if (!cids.includes(c.id)) return false
          for (const c2 of r.categories) {
            const sids = c2.subcategories.map(s => s.id)
            if (sids.includes(s.id)) return true
          }
          return false
        })

        if (!results.length) {
          continue
        }

        list2.push(
          <div key={s.name} className="subgroup">
            <div className="sname">{s.name}</div>
            <div className="items">
              {results.map((data, i) => (
                <Link key={i} className="SearchResult" to={`/${baseUrl}/${data.id}`}>
                  <Component data={data} />
                </Link>
              ))}
            </div>
          </div>
        )
      }

      if (!list2.length) {
        continue
      }

      list1.push(
        <div key={c.name} className="group">
          <div className="cname">{c.name}</div>
          {list2}
        </div>
      )
    }

    ui = <div className="SearchResults all">{list1}</div>
  } else {
    ui = (
      <div className="SearchResults">
        <div className="header">
          {searchResults.length} result{searchResults.length === 1 ? '' : 's'}
        </div>
        {searchResults.map((data, i) => (
          <Link key={i} className="SearchResult" to={`/${baseUrl}/${data.id}`}>
            <Component data={data} />
          </Link>
        ))}
      </div>
    )
  }

  return (
    <div className="SearchResultsWrapper">
      {ui}
      <div className="fade-bar" />
    </div>
  )
}

export function CombinedListItem({ data }) {
  // prettier-ignore
  const fields = _.compact([
    data.indicators.length ? [<div className="icon-label"><AssessmentIcon fontSize="medium" sx={{ color: 'rgb(241, 101, 37)' }} /> Indicators</div>, <div className="links">{data.indicators.map(i => <NavLink key={i.id} to={`/indicators/${i.id}`}>{i.name}</NavLink>)}</div>] : null,
    data.reports.length ? [<div className="icon-label"><DescriptionIcon fontSize="medium" sx={{ color: 'rgb(234, 120, 65)' }} /> Reports</div>, <div className="links">{data.reports.map(r => <NavLink key={r.id} to={`/reports/${r.id}`}>{r.name}</NavLink>)}</div>] : null,
    data.orgs.length ? [<div className="icon-label"><BusinessIcon fontSize="medium" sx={{ color: 'rgb(0, 153, 80)' }} /> Organizations</div>, <div className="links">{data.orgs.map(o => <NavLink key={o.id} to={`/orgs/${o.id}`}>{o.name}</NavLink>)}</div>] : null,
  ])

  return (
    <div className="CombinedListItem">
      <div className="header">{data.sname}</div>
      <Fields fields={fields} />
    </div>
  )
}

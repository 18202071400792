export function calculateMosaic(node: HTMLDivElement) {
  const children = [...node.children]
  let parentRect = node.getBoundingClientRect()
  const gap = 20
  let x = 0
  let y = 0
  let i = 0
  let j = 0
  let rows = []
  for (const child of children) {
    const rect = child.getBoundingClientRect()
    if (x + rect.width > parentRect.width) {
      x = 0
      j = 0
      i++
    }
    if (i > 0) {
      const prevRect = rows[i - 1][j]
      if (prevRect) {
        y = prevRect.y + prevRect.height + gap
      }
    }
    if (!rows[i]) rows[i] = []
    rows[i].push({ x, y, width: rect.width, height: rect.height })
    child.style.transform = `translate(${x}px, ${y}px)`
    x += rect.width + gap
    j++
  }
}

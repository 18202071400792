import {
  LocalAtm as LocalAtmIcon,
  School as SchoolIcon,
  HealthAndSafety as HealthAndSafetyIcon,
  House as HouseIcon,
  FoodBank as FoodBankIcon,
  Devices as DevicesIcon,
  Group as GroupIcon,
  Commute as CommuteIcon
} from '@mui/icons-material'

const icons = {
  Economy: LocalAtmIcon,
  Education: SchoolIcon,
  Health: HealthAndSafetyIcon,
  Housing: HouseIcon,
  'Food and Necessities': FoodBankIcon,
  Technology: DevicesIcon,
  'Social Capital': GroupIcon,
  Transportation: CommuteIcon
}

export function CategoryIcon({ name }) {
  const Icon = icons[name]
  if (!Icon) {
    throw new Error(`no icon for "${name}"`)
  }
  return <Icon sx={{ color: 'rgb(100,172,190)' }} />
}

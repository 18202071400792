import './TitleBar.scss'

import { useHistory } from 'react-router-dom'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export function TitleBar({ title, children }) {
  title = title || children
  const history = useHistory()

  return (
    <h2 className="TitleBar">
      <IconButton color="primary" size="small" onClick={() => history.goBack()}>
        <ArrowBackIcon />
      </IconButton>
      {title}
    </h2>
  )
}

export function Fields({ fields }) {
  const onRef = node => {
    if (!node) return
    const nodes = [...node.querySelectorAll('.label')]
    const widths = nodes.map(n => n.getBoundingClientRect().width)
    const maxWidth = Math.max(...widths)
    for (const n of nodes) {
      n.style.width = `${maxWidth}px`
    }
  }

  return (
    <div className="Fields" ref={onRef}>
      {fields.map(([label, data], i) => (
        <div key={i} className="labelvalue">
          <span className="label">{label}</span>
          <span className="value">{data}</span>
        </div>
      ))}
    </div>
  )
}

import './index.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import debug from 'debug'
import { App, Providers } from './components/App'

window._debug = debug

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/api'
} else {
  axios.defaults.baseURL = window.location.origin.replace('2231', '2230') + '/api'
}

axios.defaults.withCredentials = true

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root')
)
